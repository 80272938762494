@charset "utf-8";
@import "../media/css/vars";

$controlBorderColor: #ccc;
$controlBgColor: #ddd;
$controlPinColor: #fff;
$controlBarWidth: 16px;

.VSwitch {
	h4 {
		font-size: 140%;
		color: $titleColor;
		margin: 0;
		padding: 2px 0 2px $controlBarWidth;
	}
	> div {
		line-height: 1.4rem;
		.row {
			min-width: 100%;
			align-items: stretch;
			align-content: stretch;
			.switchLane {
				padding: 0;
				max-width: $controlBarWidth;
				min-width: $controlBarWidth;
				border-style: none solid;
				border-color: $controlBorderColor;
				border-width: 1px;
				background-color: $controlBgColor;
				position: relative;
			}

			.switchLabel {
				flex-grow: 2;
				padding: 0 0 0 Calc($controlBarWidth / 2);
				big {
					font-size: 120%;
					vertical-align: -2px;
				}
			}

			&.first {
				.switchLane {
					border-top-left-radius: $controlBarWidth;
					border-top-right-radius: $controlBarWidth;
					border-top-style: solid;
				}
			}

			&.last {
				.switchLane {
					border-bottom-left-radius: $controlBarWidth;
					border-bottom-right-radius: $controlBarWidth;
					border-bottom-style: solid;
				}
			}

			&.active {
				.switchLane {
					> span {
						position: absolute;
						left: Calc(50% - (($controlBarWidth - 2px) / 2));
						top: Calc(50% - (($controlBarWidth - 2px) / 2));
						width: Calc($controlBarWidth - 2px);
						height: Calc($controlBarWidth - 2px);
						border-radius: Calc($controlBarWidth - 2px);
						border: 1px solid $controlBorderColor;
						background-color: $controlPinColor;
					}
				}

				&.first:not(.last) {
					.switchLane {
						> span {
							top: 1px;
						}
					}
				}
				&.last:not(.first) {
					.switchLane {
						> span {
							top: unset;
							bottom: 1px;
						}
					}
				}

				.switchLabel {
					font-weight: bold;
				}
			}


			&.inactive {
				cursor: pointer;
			}
		}
	}
}