@charset "UTF-8";

$commentColor: #999;
$borderColor: #ccc;
$titleColor: #679;
//$errorColor: #900;

$mainMarginH: auto;
$mainMarginV: 1rem;
$mainMargin: $mainMarginV $mainMarginH;
$mainPadding: 1rem;
$mainBorderColor: #999;
$mainBorderWidth: 1px;
$mainBorder: $mainBorderWidth $mainBorderColor;
$mainBorderRadius: 8px;
$mainBgColor: #fff;
$shadowColor: #00000033;
$windowBgColor: #eee;
$toolbarBgColor: rgb(199, 202, 215);

$paneBorderRadius: 8px;

$panePadding: 0;
$paneBorderColor: #ccc;

$backgroundColor: #eee;
$appBackgroundColor: #fff;
//$panelBackgroundDark: #679;
$neutralBackground: #f5f5f5;
$appBackgroundSecondary: rgb(249, 251, 255);

$phoneMaxWidth: 640px;
$tabletMinWidth: 641px;
$tabletMaxWidth: 768px;
$desktopMinWidth: 769px;
$desktopWidth: 1024px;

$lighenerColor: var(--theme-lightener-color);
$darkenerColor: var(--theme-darkener-color);

$blackColor: var(--theme-black-color);
$panelBackgroundDark: var(--theme-main-dark-color);
$panelBackgroundLight: color-mix(in srgb, $panelBackgroundDark 80%, $lighenerColor 20%);
//$titleColor: color-mix(in srgb, $panelBackgroundDark 80%, $darkenerColor 20%);
$errorColor: var(--theme-error-color);
$primaryButtonBg: var(--theme-main-dark-color);
$successButtonBg: #588c58;
$dangerButtonBg: color-mix(in srgb, $errorColor 80%, $darkenerColor 20%);
$secondaryButtonBg: var(--theme-secondary-bg);
$warningButtonBg: #debf79;

/*
	Widths
Phone:	640px,
Tablet:	768px,
Deskt:	1024px
*/
@mixin mainWindowLightColors($shadowSize: 6px) {
	position: relative;
	background-color: $mainBgColor;
	h1:first-child {
		display: block;
		text-align: center;
		font-size: 100%;
		margin: 0;
		padding: 0 1rem 2px 1rem;
		color: $windowBgColor;
		background-color: $panelBackgroundDark;
		font-weight: bold;
	}
	@media screen and (min-width: $phoneMaxWidth ) {
		border-color: $mainBorderColor;
		@include dropShadow($shadowSize);
		margin: $mainMargin;
		border-width: $mainBorderWidth;
		border-style: solid;
		border-radius: $mainBorderRadius;
		overflow: hidden;
		h1:first-child {
			margin: 0 0 1rem 0;
		}
	}
}


@mixin mainWindowWidth($maxWidth: max(90vw, calc(100vw - 2rem))) {
	max-width: 100%;
	@media screen and (min-width: $phoneMaxWidth ) {
		max-width: min($maxWidth, 99%);
	}
}

@mixin mobile {
	@media screen and (max-width: $phoneMaxWidth) {
		@content;
	}
}

@mixin mainWindowFullHeight() {
	margin-bottom: 0;
	overflow: auto;
	height: calc(100vh - 64px);
	@media screen and (min-width: $phoneMaxWidth ) {
		height: calc(100vh - 90px);
	}
}

@mixin subPanelCommon( $borderRadius: $paneBorderRadius ) {
	border-color: $paneBorderColor;
	border-width: $mainBorderWidth;
	border-style: solid;
	border-radius: $borderRadius;
	@include dropShadow(2px);
}

@mixin absFullbox($top: 0, $right: 0, $bottom: 0, $left: 0) {
	position: absolute;
	top: $top;
	right: $right;
	left: $left;
	bottom: $bottom;
	margin: 0;
}

@mixin buttonsToolbar( $padding: 12px ) {
	background-color: $toolbarBgColor;
	padding: calc( $padding * .5) $padding;
}

@mixin dropShadow($size: 6px, $color: $shadowColor) {
	box-shadow: 0 $size calc($size * 2.5) $color;
}

@mixin curtain( $backgroundColor: #cccccc22 ) {
	z-index: 100;
	background-color: $backgroundColor;
	backdrop-filter: blur(4px);
	@include absFullbox();
}

@mixin inlineIcon($icon) {
	&:before {
		content: $icon;
	}
}

@mixin grayIcon($icon) {
	filter: saturate(0) opacity(0.5);
	@include inlineIcon($icon);
}

@mixin pulseOpacity($duration: 2s) {
	animation: KFpulseOpacity $duration linear infinite;
}

@mixin pulseSaturation($duration: 2s) {
	animation: KFpulseSaturation $duration linear infinite;
}

@mixin rotateAnimation($duration: 2s) {
	animation: KFrotate $duration linear infinite;
}

@keyframes KFrotate {
	from {
		rotate: 0deg;
	}
	25% {
		rotate: 90deg;
	}
	50% {
		rotate: 180deg;
	}
	75% {
		rotate: 270deg;
	}
	to {
		rotate: 360deg;
	}
}

@keyframes KFpulseOpacity {
	from {
		opacity: 1;
	}
	50% {
		opacity: 0.25;
	}
	to {
		opacity: 1;
	}
}

@keyframes KFpulseSaturation {
	from {
		filter: saturate(1) opacity(1);
	}
	50% {
		filter: saturate(0) opacity(0.25);
	}
	to {
		filter: saturate(1) opacity(1);
	}
}
