.LanguageSwitcher {
	text-align: start;
	h4 { display: none }
	.switchLabel {
		> div {
			white-space: nowrap;
			img {
				transition: filter .2s ease-in-out;
				vertical-align: baseline;
				display: inline-block;
				width: 20px;
				margin: 0 4px 0 0;
				padding: 0;
			}
		}
	}
	.inactive:not(:hover) {
		.switchLabel {
			img {
				filter: saturate(0) opacity(.5);
			}
		}
	}
}