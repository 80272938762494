@charset "UTF-8";
@import "../media/css/vars";

.App {
	&[lang="it"] {
		*[lang]:not([lang="it"]) {
			display: none;
		}
	}
	&[lang="en"] {
		*[lang]:not([lang="en"]) {
			display: none;
		}
	}
	.link {
		color: $titleColor;
		cursor: pointer;
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}
	.AppMainContent {
		position: relative;
		margin: 0;
		padding: 1rem calc( 1rem + min(25vw, 15rem)) 1rem 2rem;
		h1, h2, h3, h4 {
			color: $titleColor;
		}
		h1[class=""], h2[class=""] {
			margin: 1rem 0;
			font-weight: bold;
		}
		h3[class=""], h4[class=""] {
			margin: .5rem 0;
		}
		p {
			margin: 0 0 1rem 0;
			padding: 0;
		}
		h3.mot {
			opacity: .75;
			font-size: 1.25rem;
			font-weight: bold;
			font-style: italic;
			color: $titleColor;
			margin-left: 1rem;
			font-family: "Roboto Condensed", monospace;
			&.quote {
				&:before {
					color: $commentColor;
					content: '$_="';
					opacity: .75;
					font-style: normal;
				}
				&:after {
					color: $commentColor;
					content: ' ";';
					opacity: .75;
					font-style: normal;
				}
			}
			&:first-of-type {
				&:not(:first-child) {
					margin-top: 1rem;
				}
				margin-bottom: 1rem;
			}
			&:last-child {
				margin-bottom: 1rem;
				&:not(:first-child) {
					margin-top: 1.5rem;
				}
			}
		}

		.MainSideImage {
			top: .5rem;
			right: .5rem;
			position: absolute;
			max-width: min(25vw, 15rem);
			max-height: calc(100vh - 5rem);
			transition: max-width .5s ease-in-out;
			margin: 0;
			border-radius: 16px;
			z-index: 100;
			&:hover {
				max-width: calc(100% - 1rem);
			}
		}
	}
}

