@charset "utf-8";
@import "../media/css/vars";

.Tech {
	>.accordion {
		margin: 1rem 0;
		max-width: min(100%, 64rem);
		> .accordion-item {
			.accordion-header {
				.accordion-button {
					font-weight: bold;
					font-size: 1.2rem;
				}
			}
			> .accordion-collapse {
				> .accordion-body {
					//> h3.mot {
					//	margin-bottom: 1rem;
					//}
					//div ~ h3.mot {
					//	margin-bottom: 0;
					//	margin-top: 1rem;
					//}
					> div:not([lang]) {
						margin: .5rem 0;
						display: flex;
						align-items: start;
						column-gap: .5rem;
						> div {
							&:first-child {
								width: 2rem;
								text-align: center;
								font-size: 150%;
							}
							&:last-child {
								p {
									padding: 0;
									margin: 0;
									line-height: 1.2rem;
									&:first-child {
										font-weight: bold;
										&:last-child {
											margin-top: .4rem;
										}
									}
									&:not(:first-child) {
										font-size: 90%;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
