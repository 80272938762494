@import url("https://cdn.jsdelivr.net/npm/bootstrap@5.1.0/dist/css/bootstrap.min.css");
@import "vars";

body {
	.btn {
		padding: 0.2rem 0.5rem;
		z-index: 1;
	}

	.btn, .btn:hover, .btn-check:focus + .btn,
	.btn:focus,
	.btn:active,
	.btn.active {
		outline: none !important;
		box-shadow: none !important;
	}

	.btn.btn-icon,
	.btn-icon > .btn {
		text-align: left;
		display: inline-flex;
		flex-flow: row nowrap;
		align-items: stretch;
		gap: 0 0;
		padding: 0;
		height: 2rem;
		line-height: 1.8rem;

		> i {
			text-align: center;
			display: block;
			font-style: normal;
			padding: 0;
			width: 2rem;
			background-color: #ffffff33;
			text-shadow: 0 0 2px #000000;
			border-style: solid;
			border-color: #00000066;
			border-width: 0 1px 0 0;

			> img {
				max-width: 30px;
				max-height: 30px;
				fill: #fff;
			}
			> svg {
				max-width: 28px;
				max-height: 28px;
				margin: 1px;
			}

			&:last-child {
				border-width: 0 0 0 1px;
			}
		}

		> b {
			display: block;
			font-style: normal;
			font-weight: bold;
			padding: 0 0.5rem;
			text-align: center;
			flex-grow: 2;

			&:first-child:not(:last-child) {
				padding-left: 1rem;
			}

			&:last-child:not(:first-child) {
				padding-right: 1rem;
			}
		}

		&.disabled {
			> i,
			> b {
				background-color: #99999966;
				opacity: 0.75;
			}
		}
	}

	.btn.btn-icon.center,
	.btn-icon.center > .btn {
		text-align: center;

		> b {
			&:first-child:not(:last-child) {
				padding-left: 3rem;
				padding-right: 1rem;
			}

			&:last-child:not(:first-child) {
				padding-right: 3rem;
				padding-left: 1rem;
			}
		}
	}
}

body .accordion .accordion-header .accordion-button {
	box-shadow: none !important;
	outline: none !important;
}

table > tbody > tr:hover {
	--bs-table-accent-bg: rgba(235, 235, 235, 0.2549019608) !important;
}

body .modal[role="dialog"] {
	.modal-dialog {
		.modal-content {
			.modal-header {
				min-height: fit-content;
				padding: 4px 8px;
				background-color: rgba($commentColor, .15);
				.modal-title {
					color: $titleColor;
					font-weight: bold;
					font-size: 110%;
					> svg {
						display: inline-block;
						height: 22px;
						margin: -6px 8px -2px 0;
					}
				}
			}
			.modal-body {
				z-index: 2;
			}
			.modal-footer {
				padding: 2px 4px;
				background-color: rgba($commentColor, .15);
				display: flex;
				justify-content: flex-end;
				&:has( > * + * ) {
					justify-content: space-between;
				}
			}
		}
	}
}