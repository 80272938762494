@charset "utf-8";
@import "../media/css/vars";

.TabBar {
	background-color: $windowBgColor;
	height: 60px;
	@include mobile() {
		height: 48px;
	}
	width: 100%;
	display: flex;
	position: relative;
	gap: 0;
	ul.nav {
		align-self: end;
		flex-grow: 1;
		li.nav-item {
			margin: 0 .3rem;
			@include mobile() {
				margin: 0;
			}
			&:first-child {
				margin-left: 1rem;
				@include mobile() {
					margin-left: .5rem;
				}
			}
			a {
				&.active {
					font-weight: bold;
					color: $titleColor;
				}
				&.inactive {
					cursor: pointer;
					color: $titleColor;
				}
			}
			@include mobile() {
				.nav-link {
					padding: .25rem .5rem;
				}
			}
		}
	}
	.LanguageSwitcher {
		align-self: center;
		width: 120px;
		@include mobile() {
			transform-origin: center right;
			transform: scale(.8);
		}
	}
}