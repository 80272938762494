@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,400;0,700;1,400;1,700&family=Titillium+Web:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import "vars";

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  * {
    font-family: "Titillium Web", -apple-system, BlinkMacSystemFont, "Segoe UI",
      "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
    box-sizing: border-box;
    border-collapse: collapse;
  }
}

@media screen {
  html,
  html > body {
    background-color: $mainBgColor;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, "Courier New", monospace;
}
